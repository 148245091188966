import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: 200px;
  height: 30px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
  .date-picker-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    > div {
      width: 100%;
      height: 100%;
    }
    .input-wrapper {
      width: 100%;
      height: 100%;
      &::before,
      ::after {
        border: none !important;
      }
      input {
        font-family: 'Prmopt', sans-serif;
        padding: 8px 10px;
        font-size: 12px;
        color: ${(props) => props.theme.darkBackground};
        cursor: pointer;
      }
    }
    .date-picker-icon-container {
      position: absolute;
      top: 7px;
      right: 10px;
      width: 15px;
      height: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
`
