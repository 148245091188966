import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { MuiThemeProvider, createMuiTheme, IconButton, withStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import CalendarIcon from '../../asset/images/date-picker/calendar.svg'
import leftArrowIcon from '../../asset/images/date-picker/previous.svg'
import rightArrowIcon from '../../asset/images/date-picker/next.svg'

import DatePickerStyled from './styledComponent'
import colors from '../../colors'

const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'
const YEAR = 'year'

const materialTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: 'Prompt'
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.jadeGreen,
        fontSize: '12px',
        fontFamily: 'Prompt',
        height: 90
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: 12,
        fontFamily: 'Prompt'
      }
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: colors.darkBackground,
        color: '#fff'
      },
      current: {
        color: '#009688'
      }
    }
  }
})

const datePickerStyled = (theme) => ({
  dayContainer: {
    fontSize: 16,
    padding: 8,
    margin: '0px 4px',
    color: '#1f2d39'
  },
  hide: {
    opacity: 0.3
  },
  selectedDay: {
    color: 'white',
    background: colors.jadeGreen,
    borderRadius: '50%',
    '&:hover': {
      background: 'rgba(0,0,0,1)'
    }
  },
  selectedWeek: {
    background: 'rgba(126, 211, 33, 0.5)'
  },
  firstDay: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10
  },
  lastDay: {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10
  }
})

class DatePickerComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      datePickerOpen: false
    }
    this.datePicker = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleDatePickerChanged(this.props.value)
  }

  openDatePicker() {
    this.setState({
      datePickerOpen: true
    })
  }

  closeDatePicker() {
    this.setState({
      datePickerOpen: false
    })
  }

  renderWeekSelected(date, selectedDate, dayInCurrentMonth) {
    let className = this.props.classes.dayContainer
    const isFutureDate = date.isAfter(moment())
    if (!dayInCurrentMonth || isFutureDate) {
      className = `${className} ${this.props.classes.hide}`
    }
    const isSelectedDay = date.isSame(selectedDate)
    if (isSelectedDay) {
      className = `${className} ${this.props.classes.selectedDay}`
    }
    const startDay = moment(selectedDate).startOf('week')
    const endDay = moment(selectedDate).endOf('week').startOf('day')
    const isDayInSelectedWeek = moment(date).isBetween(startDay, endDay)
    const isSameStartDay = date.isSame(startDay)
    const isSameEndDay = date.isSame(endDay)
    let weekClassName = ''
    if (this.props.type === 'week') {
      if (isDayInSelectedWeek || isSameStartDay || isSameEndDay) {
        weekClassName += ` ${this.props.classes.selectedWeek}`
      }
      if (isSameStartDay) {
        weekClassName += ` ${this.props.classes.firstDay}`
      }
      if (isSameEndDay) {
        weekClassName += ` ${this.props.classes.lastDay}`
      }
    }
    return (
      <div className={weekClassName}>
        <IconButton className={className}>
          <span>{date.format('DD')}</span>
        </IconButton>
      </div>
    )
  }

  renderLabelDate(date) {
    let label
    switch (this.props.type) {
      case DAY: {
        label = date.format('D MMM YYYY')
        break
      }
      case WEEK: {
        const startDay = moment(date).startOf('week')
        const endDay = moment(date).endOf('week').startOf('day')
        label = `${startDay.format('D MMM YYYY')} - ${endDay.format('D MMM YYYY')}`
        break
      }
      case MONTH: {
        label = date.format('MMM YYYY')
        break
      }
      case YEAR: {
        label = date.format('YYYY')
        break
      }
      default: {
        label = date.format('D MMM YYYY')
        break
      }
    }
    return label
  }

  renderDatePicker() {
    const setting = this.getFormatSetting()
    return (
      <div className="date-picker-wrapper" id="btn-date-picker">
        <DatePicker
          variant="inline"
          value={this.props.value}
          initialFocusedDate={this.props.value}
          renderDay={(date, selectedDate, dayInCurrentMonth) => this.renderWeekSelected(date, selectedDate, dayInCurrentMonth)}
          onChange={(date) => this.handleDatePickerChanged(date)}
          animateYearScrolling
          labelFunc={(date) => this.renderLabelDate(date)}
          leftArrowIcon={<img src={leftArrowIcon} alt="" />}
          rightArrowIcon={<img src={rightArrowIcon} alt="" />}
          minDate={moment().subtract(2, 'year')}
          maxDate={moment()}
          maxDateMessage={''}
          minDateMessage={''}
          onError={() => this.handleDatePickerChanged(this.props.value)}
          openTo={setting.openTo}
          open={this.state.datePickerOpen}
          onOpen={() => this.openDatePicker()}
          onClose={() => this.closeDatePicker()}
          views={setting.views}
          invalidLabel=""
          invalidDateMessage=""
          autoOk
          disableFuture
          InputProps={{ className: 'input-wrapper text-style' }}
        />
        <div className="date-picker-icon-container">
          <img src={CalendarIcon} alt="" onClick={() => this.openDatePicker()} />
        </div>
      </div>
    )
  }

  getFormatSetting() {
    let setting
    switch (this.props.type) {
      case DAY: {
        setting = {
          views: ['date'],
          openTo: 'date'
        }
        break
      }
      case WEEK: {
        setting = {
          views: ['date'],
          openTo: 'date'
        }
        break
      }
      case MONTH: {
        setting = {
          views: ['year', 'month'],
          openTo: 'month'
        }
        break
      }
      case YEAR: {
        setting = {
          views: ['year'],
          openTo: 'year'
        }
        break
      }
      default: {
        setting = {
          views: ['date'],
          openTo: 'date'
        }
        break
      }
    }
    return setting
  }

  handleDatePickerChanged(date) {
    if (!date.isSame(this.props.value)) {
      this.setState({
        date: date
      })
      this.props.onDateChange(date)
    }
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={materialTheme}>
          <DatePickerStyled>{this.renderDatePicker()}</DatePickerStyled>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    )
  }
}

DatePickerComponent.propTypes = {
  value: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    dayContainer: PropTypes.string,
    firstDay: PropTypes.string,
    hide: PropTypes.string,
    lastDay: PropTypes.string,
    selectedDay: PropTypes.string,
    selectedWeek: PropTypes.string
  }),
  onDateChange: PropTypes.func
}

export default withStyles(datePickerStyled)(DatePickerComponent)
